import React from 'react';
import PropTypes from 'prop-types';
import {
	FacebookShareButton,
	TwitterShareButton,
} from 'react-share';

import {
	FacebookIcon,
	TwitterIcon
} from 'react-share';

const Share = ({ socialConfig, tags }) => (
	<div className="post-social">
		<div className="social-share-label" data-ter={socialConfig.config.title}>
			Share page on:
		</div>
		<FacebookShareButton url={socialConfig.config.url} className="button social-icon facebook" title={socialConfig.config.title}>
			<FacebookIcon size={32} round={false} />
		</FacebookShareButton>
		<TwitterShareButton url={socialConfig.config.url} className="button social-icon twitter" title={socialConfig.config.title} via={socialConfig.twitterHandle.split('@').join('')} hashtags={tags} >
			<TwitterIcon size={32} round={false} />
		</TwitterShareButton>
	</div>
);

Share.propTypes = {
	socialConfig: PropTypes.shape({
		twitterHandle: PropTypes.string.isRequired,
		config: PropTypes.shape({
			url: PropTypes.string.isRequired,
			title: PropTypes.string.isRequired,
		}),
	}).isRequired,
	tags: PropTypes.arrayOf(PropTypes.string),
};
Share.defaultProps = {
	tags: [""],
};

export default Share;
