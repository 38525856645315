import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import FooterShareButtons from '../components/FooterShareButton'
import FooterLatestBlogs from "../components/FooterLatestBlogs"
import { FaTwitter, FaFacebookF, FaLinkedinIn } from "react-icons/fa"

const Footer = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      verifyAddress: '',
      fullName: '',
      email: '',
      message: ''
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  shareButtons() {
    return (
      <FooterShareButtons />
    )
  }

  renderLatestPost() {
    return (
      <StaticQuery
        query={graphql`
          query LatestBolgQuery {
            allMarkdownRemark(
              sort: { order: DESC, fields: [frontmatter___date] }
              filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
            ) {
              edges {
                node {
                  excerpt(pruneLength: 50)
                  id
                  fields {
                    slug
                  }
                  frontmatter {
                    title
                    templateKey
                    date(formatString: "MMMM DD, YYYY")
                  }
                }
              }
            }
          }
        `
        } render={(data) => (
          <FooterLatestBlogs data={data} />
        )}
      />)
  }
  render() {
    return (
      <footer className="footer has-text-white-ter">
        <div className="container">
          <div className="columns">
            <div className="column is-4">
              <h4>About</h4>
              <p>
                Cypress Data Defense was founded in 2013 and is headquartered in Denver, Colorado with offices across the United States. Our goal is to help organizations secure their IT development and operations using a pragmatic, risk-based approach. The diverse background of our founders allows us to apply security controls to governance, networks, and applications across the enterprise.
              </p>
              <div style={{ marginTop: 50 }}>
                <a className="contactLink" href="/contact">Learn More</a>
              </div>
              <div style={{ marginTop: 20 }}>
                {this.shareButtons()}
              </div>
            </div>
            <div className="column is-5">
              <h4>Latest Posts</h4>
              <div>
                {this.renderLatestPost()}
              </div>
            </div>
            <div className="column is-4">
              <h4>Contact</h4>
              <p>
                Cypress Data Defense
              </p>
              <p>
                14143 Denver West Pkwy
              </p>
              <p>
                Suite 100
              </p>
              <p>
                Golden, CO 80401
              </p>
              <br />
              <p>
                PH: 720.588.8133
              </p>
              <br />
              <p>
                Email: info@cypressdatadefense.com
              </p>
              <br />
              <h4>Social</h4>
              <div>
                <div className="socialIcon">
                  <span><FaTwitter /></span>
                </div>
                <div className="socialIcon">
                  <span><FaLinkedinIn /></span>
                </div>
                <div className="socialIcon">
                  <span><FaFacebookF /></span>
                </div>
              </div>
            </div>
          </div>
          <div className="columns bottom-band">
            <div className="column is-11">
              <p>© Cypress Data Defense, LLC | 2022 - All Rights Reserved</p>
            </div>
            <div className="column is-1">
              <a className="contactLink" href="/privacy">Privacy Policy</a>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
