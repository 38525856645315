import React from 'react'
import Share from '../components/Share'
import { globalHistory } from '@reach/router'
import { StaticQuery, graphql } from "gatsby"

const FooterShareButtons = () => {

  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          site {
            siteMetadata {
              title,
              twitterHandle
            }
          }
        }
      `}
      render={data => (
        <Share
          socialConfig={{
            twitterHandle: data.site.siteMetadata.twitterHandle,
            config: {
              url: globalHistory.location.href,
              title: data.site.siteMetadata.title,
            },
          }}
        />
      )}
    />
  )
}

export default FooterShareButtons
