import React, {Component} from 'react'

class FooterLatestBlogs extends Component {
	render(){
		const { edges: posts } = this.props.data.allMarkdownRemark
		return	posts.map((post, i) => {
			if (i<3) {
				return(
					<div key={i} style={{ paddingBottom: 10, borderBottom: "1px solid rgba(255,255,255,0.035)" }}>
						<p>
							<a href={post.node.fields.slug}>{post.node.frontmatter.title}</a>
						</p>
						<p>
							{post.node.excerpt}
						</p>
					</div>
				)
			}

			return null;
		})
	}
}

export default FooterLatestBlogs
