import React from "react";
import { Link } from "gatsby";
import logo from "../img/logo.png";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
      scroll: "",
      top: "",
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  handleScroll() {
    this.setState({ scroll: window.scrollY });
  }

  componentDidMount() {
    let path = window.location.pathname;
    const el = document.getElementById("navbar");
    if (
      path.includes("/blog/") ||
      path.includes("/categories/") ||
      path.includes("/archives/") ||
      path.includes("/resources/") ||
      path.includes("/sitemap") ||
      path.includes("/terms-and-conditions")
    ) {
      this.setState({ top: 0, scroll: 1 });
    } else {
      this.setState({ top: el.offsetTop, height: el.offsetHeight });
      window.addEventListener("scroll", this.handleScroll);
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
            navBarActiveClass: "is-active",
          })
          : this.setState({
            navBarActiveClass: "",
          });
      }
    );
  };

  render() {
    return (
      <div>
        <nav
          className="navbar is-transparent is-fixed-top"
          role="navigation"
          id="navbar"
          aria-label="main-navigation"
          style={
            this.state.scroll > this.state.top
              ? {
                background: "#28363d",
                boxShadow: "0 0 5px rgba(0, 0, 0, 0.19)",
              }
              : { background: "rgba(255, 255, 255, 0.0001)", padding: "8px 0" }
          }
        >
          <div className="container">
            <div
              className="navbar-brand"
              style={
                this.state.scroll > this.state.top
                  ? { display: "block" }
                  : { display: "none" }
              }
            >
              <Link to="/" title="Logo">
                <img
                  src={logo}
                  alt="Kaldi"
                  style={{ padding: 10, width: 200 }}
                />
              </Link>
              {/* Hamburger menu */}
              <div
                className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                data-target="navMenu"
                role="button"
                tabIndex="0"
                onClick={() => this.toggleHamburger()}
                onKeyDown={() => this.toggleHamburger()}
              >
                <span />
                <span />
                <span />
              </div>
            </div>
            <div
              id="navMenu"
              className={`navbar-menu ${this.state.navBarActiveClass}`}
            >
              <div className="navbar-end has-text-centered">
                <Link className="navbar-item" to="/">
                  Home
                </Link>
                <div className="navbar-item has-dropdown is-hoverable">
                  <div className="navbar-link">What We Do</div>
                  <div className="navbar-dropdown is-hidden-mobile is-boxed">
                    <a
                      className="navbar-item"
                      href="/application-security-services/easy-ssdlc"
                    >
                      Embedded Application Security Service (EASy - Secure SDLC)
                    </a>
                    <div className="nested navbar-item dropdown">
                      <div className="dropdown-trigger">
                        <a
                          href="/security-assessments/network-security-testing"
                          className="navbar-item none-border"
                          style={{ paddingLeft: 0 }}
                        >
                          Network Security Testing
                        </a>
                      </div>
                      <div
                        className="dropdown-menu"
                        id="dropdown-menu"
                        role="menu"
                      >
                        <div className="dropdown-content">
                          <a
                            href="/security-assessments/network-security-testing/network-penetration-testing"
                            className="dropdown-item none-border"
                          >
                            Network Penetration Testing
                          </a>
                          <a
                            href="/security-assessments/network-security-testing/network-vulnerability-scanning"
                            className="dropdown-item"
                          >
                            Network Vulnerability Scanning
                          </a>
                        </div>
                      </div>
                    </div>
                    <a
                      className="navbar-item"
                      href="/security-assessments/application-security-testing"
                    >
                      Application Security Testing
                    </a>
                    <div className="nested navbar-item dropdown">
                      <div className="dropdown-trigger">
                        <a
                          className="navbar-item none-border"
                          style={{ paddingLeft: 0 }}
                          href="/security-assessments/application-security-testing/mobile-application"
                        >
                          Mobile Application
                        </a>
                      </div>
                      <div
                        className="dropdown-menu"
                        id="dropdown-menu"
                        role="menu"
                      >
                        <div className="dropdown-content">
                          <a
                            className="dropdown-item none-border"
                            href="/security-assessments/application-security-testing/mobile-application/dynamic-penetration-testing"
                          >
                            Dynamic Penetration Testing
                          </a>
                          <a
                            className="dropdown-item"
                            href="/security-assessments/application-security-testing/mobile-application/static-analysis"
                          >
                            Static Analysis Source Codes
                          </a>
                          <a
                            className="dropdown-item"
                            href="/security-assessments/application-security-testing/mobile-application/hybrid-security-analysis"
                          >
                            Hybrid Security Analysis
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="nested navbar-item dropdown">
                      <div className="dropdown-trigger">
                        <a
                          className="navbar-item none-border"
                          style={{ paddingLeft: 0 }}
                          href="/security-assessments/application-security-testing/web-application"
                        >
                          Web Application
                        </a>
                      </div>
                      <div
                        className="dropdown-menu"
                        id="dropdown-menu"
                        role="menu"
                      >
                        <div className="dropdown-content">
                          <a
                            className="dropdown-item none-border"
                            href="/security-assessments/application-security-testing/web-application/dynamic-penetration-testing-reporting"
                          >
                            Dynamic Penetration Testing & Reporting
                          </a>
                          <a
                            className="dropdown-item"
                            href="/security-assessments/application-security-testing/web-application/hybrid-analysis"
                          >
                            Hybrid Analysis
                          </a>
                          <a
                            className="dropdown-item"
                            href="/security-assessments/application-security-testing/web-application/static-analysis"
                          >
                            Static Analysis
                          </a>
                        </div>
                      </div>
                    </div>
                    <a
                      className="navbar-item none-border"
                      href="/security-assessments"
                    >
                      Security Assessments Services
                    </a>
                  </div>
                </div>

                <Link className="navbar-item" to="/about-us">
                  About Us
                </Link>
                <Link className="navbar-item" to="/blog">
                  Blog
                </Link>
                <Link className="navbar-item" to="/resources">
                  Resources
                </Link>
                <div className="navbar-item has-dropdown is-hoverable">
                  <a
                    className="navbar-link"
                    href="/secure-software-development-life-cycle"
                  >
                    What is SSDLC?
                  </a>
                  <div className="navbar-dropdown is-hidden-mobile is-boxed">
                    <a
                      className="navbar-item none-border"
                      href="/secure-software-development-life-cycle/introduction-to-ssdlc"
                    >
                      SSDLC Introduction
                    </a>
                    <a
                      className="navbar-item"
                      href="/secure-software-development-life-cycle/secure-sdlc-security-training-phase"
                    >
                      Security Training Phase
                    </a>
                    <a
                      className="navbar-item"
                      href="/secure-software-development-life-cycle/secure-sdlc-requirements-phase"
                    >
                      Requirements Phase
                    </a>
                    <a
                      className="navbar-item"
                      href="/secure-software-development-life-cycle/secure-sdlc-planning-design-phase"
                    >
                      Planning & Design Phase
                    </a>
                    <a
                      className="navbar-item"
                      href="/secure-software-development-life-cycle/secure-sdlc-development-phase"
                    >
                      Development Phase
                    </a>
                    <a
                      className="navbar-item"
                      href="/secure-software-development-life-cycle/secure-sdlc-verification-testing-phase"
                    >
                      Verification & Testing Phase
                    </a>
                    <a
                      className="navbar-item"
                      href="/secure-software-development-life-cycle/secure-sdlc-release-phase"
                    >
                      Release Phase
                    </a>
                  </div>
                </div>
                <Link className="navbar-item" to="/contact">
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  }
};

export default Navbar;
