import React from 'react'
import Helmet from 'react-helmet'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import './all.scss'
import useSiteMetadata from './SiteMetadata'

const TemplateWrapper = ({
  children
}) => {

  const {
    title,
    description
  } = useSiteMetadata()

  let meta_title = title
  if (children.props && children.props.meta_title) {
    meta_title = children.props.meta_title
  }

  return (<
    div >
    <
    Helmet >
      <
        html lang="en" />
      <
    title > {
          title
        } < /title> <
          meta name="description"
          content={
            description
          }
        />

        <
          link rel="apple-touch-icon"
          sizes="180x180"
          href="/img/Cypress-Data-Defense-Website-Favicon.png" /
        >
        <
          link rel="icon"
          type="image/png"
          href="/img/Cypress-Data-Defense-Website-Favicon.png"
          sizes="32x32" /
        >
        <
          link rel="icon"
          type="image/png"
          href="/img/Cypress-Data-Defense-Website-Favicon.png"
          sizes="16x16" /
        >

        <
          link rel="mask-icon"
          href="/img/safari-pinned-tab.svg"
          color="#ff4400" /
        >
        <
          meta name="theme-color"
          content="#fff" />

        <
          meta property="og:type"
          content="business.business" />
        <
          meta property="og:title"
          content={
            meta_title
          }
        /> <
          meta property="og:url"
          content="/" />
        <
          meta property="og:image"
          content="/img/og-image.jpg" />
        <
    /Helmet> <
          Navbar />
        <
    div > {
            children
          } < /div> <
            Footer />
          <
    /div>
  )
}

export default TemplateWrapper
